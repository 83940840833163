import Flickity from "react-flickity-component"
import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import ProjectFacts from "../../components/project-facts/project-facts"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import Stage from "../../components/stage/stage"
import appImg1 from "../../images/projects/tournament/app-1.png"
import appImg2 from "../../images/projects/tournament/app-2.png"
import appImg3 from "../../images/projects/tournament/app-3.png"
import appImg4 from "../../images/projects/tournament/app-4.png"
import appImg5 from "../../images/projects/tournament/app-5.png"
import appImg6 from "../../images/projects/tournament/app-6.png"
import appImg7 from "../../images/projects/tournament/app-7.png"
import introImg from "../../images/projects/tournament/intro.png"
import journeyImg from "../../images/projects/tournament/journey.png"
import outcomeImg from "../../images/projects/tournament/outcome.png"
import scenarioMappingImg from "../../images/projects/tournament/scenario-mapping.png"
import wireframesScenarioImg from "../../images/projects/tournament/wireframes-scenario.jpg"
import wireframingImg from "../../images/projects/tournament/wireframing.jpg"

const TournamentApp = () => (
  <Layout>
    <Seo title="Tournament Mobile App" />

    <Section>
      <h1>Tournament Mobile App</h1>
      <p>
        Croatian Soccer Association of Australia (CSAA) founded in 1974 to
        assist in the development and promotion of all its member clubs - Soccer
        Clubs in Australia (&amp; NZ) that were formed/founded by local Croatian
        communities in each state.
      </p>

      <ProjectFacts
        className="mb-4 border-top"
        name="CSAA Savez"
        type="UX/UI Design"
        year="2018"
      />

      <Image img={introImg} />

      <div className="row">
        <div className="col-12 col-md-6 border-right pr-md-5">
          <h2 className="mb-4">Business goal</h2>

          <p>
            Provide soccer fans and communities with an opportunity to follow
            their favourite Croatian team or club in the annual
            Australian-Croatian soccer tournament. Additionally, keeping
            everyone up to date with the latest news, fixtures and results of
            the largest and oldest ethnic football tournament in Australia.
          </p>
        </div>
        <div className="col-12 col-md-6 pl-md-5">
          <h2 className="mb-4">My role</h2>

          <p>
            Based on research provided by the client, I developed a customer
            journey map, wireframes and user interface maintaining a design
            language across iOS and Android.
          </p>
        </div>
      </div>
    </Section>

    <Section heading="Outcome" background={true}>
      <p>
        iOS and Android mobile applications were designed to allow soccer fans
        and their communities to stay connected and keep up to date with all
        upcoming and past events.
      </p>
      <p>
        The development of the application was supported by Sentia Australia and
        donated to the Croatian Soccer Association of Australia as part of their
        community program.
      </p>
    </Section>

    <Section heading="What did the process look like?">
      <div className="d-flex justify-content-between flex-column flex-sm-row mb-5">
        <Stage stage="Concept">
          <ul className="text-left">
            <li>Customer Journey map</li>
            <li>User Experience concept</li>
            <li>Wireframes</li>
          </ul>
        </Stage>
        <Stage stage="design">
          <ul className="text-left">
            <li>Stakeholder feedback</li>
            <li>User Interface </li>
            <li>User feedback</li>
          </ul>
        </Stage>
        <Stage stage="build">
          <ul className="text-left">
            <li>UI review</li>
            <li>QA</li>
          </ul>
        </Stage>
        <Stage stage="Release &amp; Learn">
          <ul className="text-left">
            <li>Data insights</li>
            <li>User feedback</li>
            <li>Stakeholder feedback</li>
          </ul>
        </Stage>
      </div>

      <h3>Customer journey mapping</h3>
      <p className="mb-5">
        To put myself into the customer’s shoes and to understand user path
        context in which the application will be used, I developed a customer
        journey map. The journey covers the whole user path, starting from
        awareness, attending events or viewing game results, finishing with
        viewing pictures, comments and following clubs or teams.
      </p>

      <Image img={journeyImg} caption="Customer journey map" />
    </Section>

    <Section>
      <h3>Wireframing</h3>
      <p className="mb-5">
        I sketched an end to end experience for new and existing community
        members, defining each screen layout, navigational system, and how they
        work together.
      </p>

      <Image
        img={wireframingImg}
        caption="First concept sketching with pen and paper"
      />
    </Section>

    <Section background={true}>
      <h3>Learnings</h3>
      <p>Additional features were introduced to the application:</p>

      <ul>
        <li>
          Polls were introduced - as it’s a fun way to increase user engagement
          before and after games.
        </li>
        <li>
          Past winners' view - allows a quick overview of past tournament
          winners
        </li>
        <li>
          About us - new users wanted to learn about the soccer association
        </li>
        <li>
          Hall of Fame - to honour the individuals who have been providing
          ongoing support to the Croatian Soccer Association of Australia
        </li>
      </ul>
    </Section>

    <Section>
      <Image img={scenarioMappingImg} />
      <Image
        img={wireframesScenarioImg}
        caption="Wireframing based on scenario mapping"
      />
    </Section>

    <Section heading="User Interface" background={true}>
      <p className="mb-5">
        One of the most exciting things about building this app was to be able
        to experiment and have the liberty to explore.
      </p>

      <Flickity
        className={"main-carousel"} // default ''
        elementType={"div"} // default 'div'
        options={{
          initialIndex: 0,
        }}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <div className="carousel-cell">
          <img src={appImg1} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg2} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg3} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg4} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg5} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg6} alt="User Interface"/>
        </div>
        <div className="carousel-cell">
          <img src={appImg7} alt="User Interface"/>
        </div>
      </Flickity>
    </Section>

    <Section>
      <h3>Working with developers</h3>
      <p>
        I worked very closely with Android, iOS and Back End developers while I
        was wireframing, defining the concept and then designing the user
        interface as we had a very tight deadline to deliver this project.
      </p>
      <p className="mb-5">
        It was essential to communicate all ideas, concepts and technology
        stacks at the very early stages to be able to stay with the project
        timeline and deliver the best possible solution.
      </p>

      <h3>Outcome</h3>
      <p className="mb-5">
        The app was build and released to the community in September 2018 just
        in time for 44th CSAA Soccer Tournament.
      </p>
      <Image img={outcomeImg} />
      <PrevNext
        prev="/project/deputy-gamification"
        next="/project/deputy-compliance"
      />
    </Section>
  </Layout>
)

export default TournamentApp
